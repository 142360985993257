import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {GetUserData,EditUserData,PostUserData} from '../model/userModel';
import { Observable } from "rxjs/observable";
@Injectable()
export class UserApi{
constructor(private http: HttpClient){ }
baseUrl = environment.baseUrl;

getUserData():Observable<GetUserData[]>{
  return this.http.get<GetUserData[]>(this.baseUrl + '/user/getUser');
}
getAllUserDetails():Observable<GetUserData[]>{
  return this.http.get<GetUserData[]>(this.baseUrl + '/user/getAllUser');
}
postUser(postUserData:PostUserData){
return this.http.post<any>(this.baseUrl + '/user/userDetails/0', postUserData);
}
editUser(editUserData:EditUserData){
  return this.http.post<any>(this.baseUrl + '/user/userDetails/' + editUserData.p_userID, editUserData);
  }
  deleteUserApi(id){
    return this.http.delete<any>(this.baseUrl + '/user/deleteUser/'+ id);
  }
}
