import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {GetUserModule,UserAccessScreen} from '../model/userModuleAccess';
import { Observable } from "rxjs/observable";
@Injectable()
export class UserAccessModuleApi{
constructor(private http: HttpClient){ }
baseUrl = environment.baseUrl;

getUserModule(p_username):Observable<GetUserModule[]>{
  return this.http.get<GetUserModule[]>(this.baseUrl + '/useraccess/getUserAccess/'+p_username);
}

postUserScreenModule(userAccessScreen:UserAccessScreen):Observable<UserAccessScreen[]>{
  return this.http.post<any>(this.baseUrl + '/useraccess/postModuleAccess/0',userAccessScreen );
}
postUserdelete(userAccessScreen:UserAccessScreen):Observable<UserAccessScreen[]>{
  return this.http.post<any>(this.baseUrl + '/useraccess/postModuleAccess/'+userAccessScreen.p_userRoleID,userAccessScreen );
}
editUserRole(userAccessScreen:UserAccessScreen):Observable<UserAccessScreen[]>{
  return this.http.post<any>(this.baseUrl + '/useraccess/updateUserRole/' ,userAccessScreen );
}
getUserRoles():Observable<UserAccessScreen[]>{
  return this.http.get<any>(this.baseUrl + '/useraccess/userRoles/0');
}
getAllUserRoles():Observable<UserAccessScreen[]>{
  return this.http.get<any>(this.baseUrl + '/useraccess/allUserRoles/1');
}
getEditRoles(data):Observable<UserAccessScreen[]>{
  return this.http.get<any>(this.baseUrl + '/useraccess/userRoles/'+ data);
}
deleteUserApi(p_id){
  return this.http.delete<any>(this.baseUrl + '/useraccess/deleteUserRoles/'+ p_id);
}
}
