import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {GetLocationData,EditLocationData,PostLocationData} from '../model/locationModel';
import { Observable } from "rxjs/observable";
@Injectable()
export class LocationApi{
constructor(private http: HttpClient){ }
baseUrl = environment.baseUrl;
// get all employeedata
// getAllEmployeeData():Observable<GetLocationData[]>{
//   return this.http.get<GetLocationData[]>(this.baseUrl + '/employee/getAllEmployee');
// }

getLocationData():Observable<GetLocationData[]>{
  return this.http.get<GetLocationData[]>(this.baseUrl + '/location/getLocation/0');
}
getAllLocationData():Observable<GetLocationData[]>{
  return this.http.get<GetLocationData[]>(this.baseUrl + '/location/getLocation/-1');
}
postLocation(postLocationData:PostLocationData){
return this.http.post<any>(this.baseUrl + '/location/postLocation/0', postLocationData);
}
editLocation(editLocationData:EditLocationData){
  return this.http.post<any>(this.baseUrl + '/location/postLocation/'+editLocationData.proLocationID,editLocationData );
  }
  deleteLocationApi(id){
    return this.http.delete<any>(this.baseUrl + '/location/deleteLocation/'+id);
  }
}
