import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from "./login/login.component";
import { NbAuthComponent } from '@nebular/auth';
// const routes: Routes = [
//   {
//        path: 'login',
//         component: LoginComponent,
//       },

// ];
export const routes: Routes = [

      {
        path: '',
        component: LoginComponent, // <---
      },
    ]



@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
