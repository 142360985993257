import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {GetEmployeeData,PostEmployeeData,EditEmployeeData} from '../model/employeeModel';
import { Observable } from "rxjs/observable";
import {ShoeReport,POReport,MachineReport} from '../model/report';
@Injectable()
export class EmployeeApi{
constructor(private http: HttpClient){ }
baseUrl = environment.baseUrl;
// get all employeedata
getAllEmployeeData():Observable<GetEmployeeData[]>{
  return this.http.get<GetEmployeeData[]>(this.baseUrl + '/employee/getAllEmployee');
}
getShoeData(id):Observable<GetEmployeeData[]>{
  return this.http.get<GetEmployeeData[]>(this.baseUrl + '/employee/getShoeIssued/'+id);
}
getEmployeeData():Observable<GetEmployeeData[]>{
  return this.http.get<GetEmployeeData[]>(this.baseUrl + '/employee/getEmployee');
}
postEmployee(postEmployeeData:PostEmployeeData){
return this.http.post<any>(this.baseUrl + '/employee/postEmployee/0', postEmployeeData);
}
editEmployee(editEmployeeData:EditEmployeeData){
  return this.http.post<any>(this.baseUrl + '/employee/postEmployee/' + editEmployeeData.p_empID, editEmployeeData);
  }
  deleteEmployeeApi(id){
    return this.http.delete<any>(this.baseUrl + '/employee/deleteEmployee/'+ id);
  }
  getShoeReport(shoeReport:ShoeReport){
    return this.http.post<any>(this.baseUrl + '/report/getReportShoeissue', shoeReport);
    }

    getPoReport(poReport:POReport){
      return this.http.post<any>(this.baseUrl + '/report/getReportPurchaseOrder', poReport);
      }
   getMachineReport(machineReport:MachineReport){
        return this.http.post<any>(this.baseUrl + '/report/getReportMachinery', machineReport);
        }
  getVechileReport(vechileReport:MachineReport){
          return this.http.post<any>(this.baseUrl + '/report/getReportVechile', vechileReport);
          }
}
