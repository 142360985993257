<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><img src="assets/images/logo.png" width="50%"></a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>
<span class="header-title">PPC SYSTEM</span>
<div class="header-container">
  <nb-actions size="small">
    <nb-action class="mat-ripple control-item contact-us" >

        <a style=" text-transform: capitalize;">{{p_username}}</a>
        <div [nbPopover]="templateRef" class="profile" ><img src="assets/images/profile.png" ></div>
        <ng-template #templateRef>
          <p class="pt-2">Change password</p>
          <p class="pt-1" (click)="profile()">Logout</p>
        </ng-template>
    </nb-action>
    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture">
      </nb-user>
    </nb-action>-->
  </nb-actions>
</div>
