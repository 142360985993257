import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {PostAssetsData} from '../model/assets';
import { Observable } from "rxjs/observable";
@Injectable()
export class AssetsApi{
constructor(private http: HttpClient){ }
baseUrl = environment.baseUrl;
getAssetsData():Observable<PostAssetsData[]>{
  return this.http.get<PostAssetsData[]>(this.baseUrl + '/assets/getAssets');
}
postAssets(postLocationData:PostAssetsData){
return this.http.post<any>(this.baseUrl + '/assets/postAsset/0', postLocationData);
}
editAssets(editdata:PostAssetsData){
  return this.http.post<any>(this.baseUrl + '/assets/postAsset/'+editdata.p_assetID,editdata );
  }
deleteAssets(p_id){
    return this.http.delete<any>(this.baseUrl + '/assets/deleteAssets/'+p_id);
  }
  // get contact data
getGettagmaterial(id){
  return this.http.get<any>(this.baseUrl + '/purchaseRequisitions/getTagmaterial/'+id);
}
}
