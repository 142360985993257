import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {PostSupplierData,GetSupplierData,EditSupplierData} from '../model/supplierModel';
import { Observable } from "rxjs/observable";
import { NgForm } from "@angular/forms";
@Injectable()
export class SupplierApi{
constructor(private http: HttpClient){ }
baseUrl = environment.baseUrl;
// get all Supplierdata
getAllSupplierData():Observable<GetSupplierData[]>{
  return this.http.get<GetSupplierData[]>(this.baseUrl + '/supplier/getAllSupplier');
}

 getSupplierData():Observable<GetSupplierData[]>{
   return this.http.get<GetSupplierData[]>(this.baseUrl + '/supplier/getSuppliers');
 }
postSupplier(postSupplierData:PostSupplierData){
return this.http.post<any>(this.baseUrl + '/supplier/postSupplier/0', postSupplierData);
}
 editSupplier(editSupplierData:EditSupplierData){
   return this.http.post<any>(this.baseUrl + '/supplier/postSupplier/' + editSupplierData.proSupplierID, editSupplierData);
  }
deleteSupplierApi(proSupplierID){
     return this.http.delete<any>(this.baseUrl + '/supplier/deleteSupplier/'+ proSupplierID);
  }
}
