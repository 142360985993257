import { Component, OnInit } from '@angular/core';
import { AuthApi } from "../../services/auth.services";
import { NgForm } from "@angular/forms";
import {AuthModel} from '../../model/authModel';
import { Router } from "@angular/router";
// import { NbToastrService, NbComponentStatus } from '@nebular/theme';
@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private _authApi :AuthApi,private router:Router) { }


  ngOnInit(): void {
    const el = document.getElementById('nb-global-spinner');
    if (el) {
      el.style['display'] = 'none';
    }
     }
  // Login function
  // created date:4.8.2020
  // author keerthiseealn
  // updated On
  loginData :AuthModel = new AuthModel()
  login(){
    this._authApi.loginAuth(this.loginData).subscribe((data)=>{
       if(data.success ==1){
        sessionStorage.setItem("username", this.loginData.p_username);
      this.router.navigate(['pages'])

      }else alert(data.data)
    },error =>console.log(error)
    )
 }

}
