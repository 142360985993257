import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs/observable";
import {
  Category,
  Uom,
  Additem,
  Getitem,
  EditcategoryData,
  EdituomData,
} from "../model/categoryuom";
@Injectable()
export class ItemsService {
  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) {}
  postCategory(postCategoryData: Category) {
    return this.http.post<any>(
      this.baseUrl + "/items/postCategory",
      postCategoryData
    );
  }
  updateCategory(editcategoryData: EditcategoryData) {
    // return this.http.put<any>(
    //   this.baseUrl + "/items/updateCategory/" + editcategoryData.procategoryID,
    //   editcategoryData
    // );
    return this.http.put<any>(
      this.baseUrl + "/items/updateCategory",
      editcategoryData
    );
  }
  updateUom(edituomData: EdituomData) {
    // return this.http.put<any>(this.baseUrl + '/items/updateUom/'+ edituomData.prouomID,edituomData );
    return this.http.put<any>(this.baseUrl + "/items/updateUom", edituomData);
  }

  postUom(postUomData: Uom) {
    return this.http.post<any>(this.baseUrl + "/items/postUom", postUomData);
  }
  getCategoryData(): Observable<Category[]> {
    return this.http.get<Category[]>(this.baseUrl + "/items/getcatUom/0");
  }
  getUomData(): Observable<Uom[]> {
    return this.http.get<Uom[]>(this.baseUrl + "/items/getcatUom/1");
  }
  postitem(postItemData: Additem) {
    return this.http.post<any>(
      this.baseUrl + "/items/postitem/0",
      postItemData
    );
  }

  deleteCategory(procategoryID) {
    return this.http.delete<any>(
      this.baseUrl + "/items/deleteCategory/" + procategoryID
    );
  }

  deleteUom(prouomID) {
    return this.http.delete<any>(this.baseUrl + "/items/deleteUom/" + prouomID);
  }
  getItemData(): Observable<Getitem[]> {
    return this.http.get<Getitem[]>(this.baseUrl + "/items/getItem/0");
  }
  getItemPriceData(id): Observable<Getitem[]> {
    return this.http.get<Getitem[]>(this.baseUrl + "/items/getItemPrice/" + id);
  }

  postitemPriceupdate(editdata: Additem) {
    return this.http.post<any>(this.baseUrl + "/items/resetPrice/", editdata);
  }
  itemUpdate(postItemData: Additem) {
    return this.http.post<any>(
      this.baseUrl + "/items/updateItem/" + postItemData.p_id,
      postItemData
    );
  }
  itemPriceupdate(editdata: Additem) {
    return this.http.post<any>(
      this.baseUrl + "/items/updateitemPrice/" + editdata.p_id,
      editdata
    );
  }
  deleteItemApi(p_id) {
    return this.http.delete<any>(this.baseUrl + "/items/deleteItem/" + p_id);
  }
}
