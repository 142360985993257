<div class="container mt-4">
  <div class="row">
    <div class="col-sm-5 pt-3">
      <img src="assets/images/logo1.png" class="pt-3 pl-3" />
      <form #loginForm="ngForm" (ngSubmit)="login()">
        <div class="pt-5 pl-5">
          <p class="mb-0">Username:</p>
          <input
            type="text"
            class="form_filed"
            name="username"
            [(ngModel)]="loginData.p_username"
          />
          <p class="mt-2 mb-0">Password:</p>
          <input
            type="password"
            class="form_filed"
            name="password"
            [(ngModel)]="loginData.p_password"
          />
        </div>
        <div
          class="d-flex mt-5 justify-content-between pl-5"
          style="width: 90%"
        >
          <span>Forget Password?</span>
          <button nbButton status="primary" type="submit">Login</button>
        </div>
      </form>
    </div>
    <div class="col-sm-7 left-design pb-5 pt-3">
      <img src="assets/images/login.png" class="pt-2" />
      <h1>Welcome to <span class="higlight">Chan & Chan</span></h1>
      <h5>Login to access your account</h5>
    </div>
  </div>
</div>
